.header {
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 12rem;
    height: 3.5rem;
}
.header-menu{
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    color: rgb(59, 9, 9);
}
.header-menu>li{
    cursor: pointer;
    color: white;
}
.header-menu>li:hover{
    color: var(--orange);
}

@media screen and (max-width: 768px) {
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background-color: var(--appcolor);
        padding: 2rem;
    }
    .menu{
        width: 2.3rem;
    }
    .logo{
        width: 5rem;
        height: 2rem;
    }
}
