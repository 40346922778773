.hero{
    display: flex;
    justify-content: space-between;
}
.left-h {
    padding: 2rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.right-h{
    flex: 1;
    position: relative;
    background-color: var(--orange);
}
.adv{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.adv>span{
    z-index: 2;
}
.adv>div{
   position: absolute;
   background-color: var(--orange);
   width: 5.4rem;
   height: 80%;
   left: 8px;
   border-radius: 3rem;
   z-index: 1;
}
.heading{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
    font-size: 3.7rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}
.heading>div:nth-of-type(3){
    font-style: 1rem;
    font-weight: 200;
    font-size: large;
}
.stats{
    display: flex;
    gap: 2rem;
}
.stats>div{
    display: flex;
    flex-direction: column;
}
.stats>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}
.stats>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: uppercase;
}

.button{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}
.button>:nth-child(1){
    color: white;
    background-color: var(--orange);
    width: 8rem;
}
.button>:nth-child(2){
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}

.right-h> .btn{
    position: absolute;
    right: 3rem;top: 2rem;color: black;
}
.cricket-player{
    position: absolute;
    right: 8rem;
    top: 4rem;
    width: 20rem;
}
.cricket-ball{
    position: absolute;
    top: 7rem;
    right: 20rem;
    z-index: -1;
    width: 3rem;
}
.hero-blur{
    width: 22rem;
    height: 30rem;
    left: 0;
}

@media screen and (max-width: 768px) {
    .hero{
        flex-direction: column;
    }
    .hero-blur{
        width: 14rem;
    }
    .adv{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }
    .heading{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .stats>div>span:nth-of-type(1){
        font-size: large;
    }
    .stats>div>span:nth-of-type(2){
        font-size: small;
    }
    .right-h{
        position: relative;
        background: none;
    }
    .cricket-player{
        position: relative;
        width: 13rem;
        left: 5rem;
        top: 5.5rem;
        align-self: center;
    }
    .cricket-ball{
        width: 3rem;
        left: 11rem;
        top: 5rem;
    }
}