:root {
  --lightgray: #D9D9D9;
  --gray: #9C9C9C;
  --orange: #F48915;
  --darkgray: #464D53;
  --calorycard: #656565;
  --planCard: linear-gradient(210.41deg,#FA5042 1.14%, #FFA739 100.75%);
  --testColor: linear-gradient(90deg, rgba(236,230,8,1) 0%, rgba(247,220,7,0.7707457983193278) 45%, rgba(255,111,0,1) 100%);
  --appcolor: #4f535a;
}

.App {
  background-color: var(--appcolor);
  mix-blend-mode: overlay;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}
.stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

::-webkit-scrollbar{
  display: none;
}

.btn{
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn:hover{
  cursor: pointer;
}

.blur{
  background: rgba(253, 120, 43, 0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;
}