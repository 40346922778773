.f-container{
    position: relative;
}
.f-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    padding:  1rem 2rem;
    height: 10rem;
}
.logos{
    display: flex;
    gap: 4rem;
   
}
.logos>img{
    width: 2rem;
    cursor: pointer;
}
.c-logo{
    padding: 1rem;
    color: white;
}
.f-blur-1{
    bottom: 0;
    width: 26rem;
    right: 15%;
    height: 12rem;
    filter: blur(200px);
    background: red;
}
.f-blur-2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}

@media screen and (max-width:768px){
    .logos>img{
       width: 1.3rem;
    }
    .c-logo{
        font-size: xx-small;
    }
}